interface XhrOptions {
  authToken: string;
  method?: string;
}

export function csrfToken(): string {
  const elem =
    typeof document !== "undefined" &&
    document.querySelector("[name=csrf-token]");

  if (!elem) {
    return "";
  }

  return elem.getAttribute("content") || "";
}

function hiddenField(name: string, value: string) {
  const field = document.createElement("input");
  field.type = "hidden";
  field.name = name;
  field.value = value;
  return field;
}

export function jsonFetchHeaders() {
  return {
    "Content-Type": "application/json; charset=utf-8",
    "X-CSRF-Token": csrfToken()
  };
}

export function submitDeleteForm(url: string) {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = url;
  form.appendChild(hiddenField("_method", "delete"));
  form.appendChild(hiddenField("authenticity_token", csrfToken()));
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export function xhr<T = unknown>(
  url: string,
  options: XhrOptions,
  callback: (response: T) => void
) {
  const xhr = new XMLHttpRequest();
  xhr.open(options.method || "POST", url, true);
  xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
  if (options.authToken) {
    xhr.setRequestHeader("X-CSRF-Token", options.authToken);
  }
  xhr.onload = () => {
    if (xhr.readyState == 4 && xhr.status == 200) {
      if (callback) {
        callback(JSON.parse(xhr.responseText) as T);
      }
    }
  };
  return xhr;
}
